export const BASE_URL = process.env.REACT_APP_API_URL;
export const CONTROLLER_TYPE = {
    CONTROLLER_TYPE_SERVER: "service/",
    AUTH: 'auth/',
    USER: 'user/',
    DASHBOARD: 'dashboard/',
    PLAN: 'plan/',
    SME_ASSET: 'smeasset/',
    ASSET: 'asset/',
    FEEDBACK: BASE_URL + 'feedback/',
    consumerlite: "consumerlite-invite/",
    SUBSCRIPTIONS: 'subscriptions/',
    BANNEDWALLETNAME:'bannedwallets/'
};
export const USER_LOGIN = BASE_URL + CONTROLLER_TYPE.AUTH + "signinadmin";
export const USER_SIGNUP = BASE_URL + CONTROLLER_TYPE.AUTH + "signupAdmin";
export const FORGOT_PASSWORD = BASE_URL + CONTROLLER_TYPE.USER + "forgotLitePassword";
export const BUSSINESSLITE_DASHBOARD = BASE_URL + CONTROLLER_TYPE.DASHBOARD + "readBusinessLiteDashboard";
export const BUSSINESSLITE_USERS = BASE_URL + CONTROLLER_TYPE.DASHBOARD + "readBusinessLiteUsers";
export const CONSUMERLITE_USERS = BASE_URL + CONTROLLER_TYPE.DASHBOARD + "readConsumerLiteUsers";
export const CONSUMERLITE_DASHBOARD = BASE_URL + CONTROLLER_TYPE.DASHBOARD + "readConsumerLiteDashboard";
export const READ_ORGANISATIONS = BASE_URL + CONTROLLER_TYPE.DASHBOARD + "readOrganisations";
export const USER_TOKEN = BASE_URL + CONTROLLER_TYPE.DASHBOARD + "addFreeUserToken";
export const INCREASE_SUBSCRIPTION = BASE_URL + CONTROLLER_TYPE.SUBSCRIPTIONS + "increaseSubscriptionPeriod";
export const EXPORT_BUSINESSLITE_TOKENS = BASE_URL + CONTROLLER_TYPE.DASHBOARD + "downloadBusinessLiteTokensExcel";
export const EXPORT_CONSUMERLITE_TOKENS = BASE_URL + CONTROLLER_TYPE.DASHBOARD + "downloadConsumerLiteTokensExcel";
export const EXPORT_BUSINESSLITE_USERS = BASE_URL + CONTROLLER_TYPE.DASHBOARD + "downloadBusinessUserExcel";
export const EXPORT_CONSUMERLITE_USERS = BASE_URL + CONTROLLER_TYPE.DASHBOARD + "downloadConsumerUserExcel";
export const EXPORT_USERS_REPORT = BASE_URL + CONTROLLER_TYPE.DASHBOARD + "downloadUserReportExcel";
export const READ_USER_REPORT = BASE_URL + CONTROLLER_TYPE.DASHBOARD + "readUserReport";
export const DOWNLOAD_EXCEL = BASE_URL + CONTROLLER_TYPE.DASHBOARD + "downloadExcel";
export const GET_USER_DETAILS = BASE_URL + CONTROLLER_TYPE.USER + "getUserDetails"
export const GET_UPDATE_USERNAME = BASE_URL + CONTROLLER_TYPE.USER + "updateUserName"
export const HANDLE_USER_STATUS = BASE_URL + CONTROLLER_TYPE.USER + "activateInactivateUser"
export const GET_UPDATE_USERPASSWORD = BASE_URL + CONTROLLER_TYPE.CONTROLLER_TYPE_SERVER + CONTROLLER_TYPE.AUTH + "change-password";
export const READ_PLAN = BASE_URL + CONTROLLER_TYPE.PLAN + "readPlan";
export const ADD_PLAN = BASE_URL + CONTROLLER_TYPE.PLAN + "addPlan";
export const UPDATE_PLAN = BASE_URL + CONTROLLER_TYPE.PLAN + "updatePlan";
export const DELETE_PLAN = BASE_URL + CONTROLLER_TYPE.PLAN + "deletePlan";
export const READ_FREE_TOKEN = BASE_URL + CONTROLLER_TYPE.SME_ASSET + "readFreeToken/";
export const ADD_UPDATE_FREE_TOKEN = BASE_URL + CONTROLLER_TYPE.SME_ASSET + "addFreeToken";
export const UPDATE_USER_PROFILE = BASE_URL + CONTROLLER_TYPE.ASSET + "uploadFiles";
export const FEEDBACK_LIST = CONTROLLER_TYPE.FEEDBACK + "listAllFeedbacks";
export const USER_LIST = BASE_URL + CONTROLLER_TYPE.DASHBOARD + "readAdminUsers"
export const INVITE_SEND = BASE_URL + "consumerlite-invite/inviteadmin";
export const ACCEPT_INVITE = BASE_URL + "consumerlite-invite/acceptadmin";
export const ATTACHMENT_CONFIG = BASE_URL + CONTROLLER_TYPE.DASHBOARD + "createConfiguration"
export const READ_PLAN_CONFIG = BASE_URL + CONTROLLER_TYPE.DASHBOARD + "readConfigurationByUserType";
export const LIST_ALL_USER_ASSETS = BASE_URL + CONTROLLER_TYPE.SME_ASSET + "listAllUserAssets";
export const UPDATE_FEEDBACK = CONTROLLER_TYPE.FEEDBACK + "updateFeedbackStatus";
export const GET_STATUS = CONTROLLER_TYPE.FEEDBACK + "getStatus"
export const READ_SUBSCRIPTION = BASE_URL + CONTROLLER_TYPE.SUBSCRIPTIONS + "readSubscription";
export const ADD_SUBSCRIPTION = BASE_URL + CONTROLLER_TYPE.SUBSCRIPTIONS + "addSubscription";
export const UPDATE_SUBSCRIPTION = BASE_URL + CONTROLLER_TYPE.SUBSCRIPTIONS + "updateSubscription";
export const DELETE_SUBSCRIPTION = BASE_URL + CONTROLLER_TYPE.SUBSCRIPTIONS + "deleteSubscription";
export const GET_TOKEN_DETAILS = BASE_URL + CONTROLLER_TYPE.SME_ASSET + "getSMEAssetDetailsForUser";
export const UPLOAD_LIST_ATTACHMENTS = BASE_URL + CONTROLLER_TYPE.SME_ASSET + "listAttachments";
export const GET_TOKEN_HISTORY = BASE_URL + CONTROLLER_TYPE.SME_ASSET + "getTransferHistory";
export const ADD_WALLET_NAME =BASE_URL + CONTROLLER_TYPE.BANNEDWALLETNAME + "addbannedWalletName";
export const WALLET_NAME_LIST=BASE_URL + CONTROLLER_TYPE.BANNEDWALLETNAME +"listBannedWalletNames";
export const UPDATE_WALLET_NAME=BASE_URL + CONTROLLER_TYPE.BANNEDWALLETNAME +"updateBannedWalletName";
export const DELETE_WALLET_NAME=BASE_URL + CONTROLLER_TYPE.BANNEDWALLETNAME +"deletebannedWalletName";
export const ADD_EMAIL_WALLET=BASE_URL + CONTROLLER_TYPE.BANNEDWALLETNAME +"assignWalletNameEmail";
export const CHANGE_ADMIN_ROLE=BASE_URL + CONTROLLER_TYPE.USER +"updateUserRole";
export const  GET_USER_TRANSACTIONS = BASE_URL + CONTROLLER_TYPE.DASHBOARD + "getUserTransactions"



