import React, { useEffect, useState } from 'react'
import { callHttpRequest, methodType } from '../../utility-files/api-util/HttpRequest';
import * as Global from '../../constants/Global';
import * as DataHandler from '../../utility-files/data-util/DataHandler'
import { alertTypes} from '../../utility-files/helper-function/HelperFunction';
import { useAlert } from 'react-alert';
import { getRequestForApi } from '../../utility-files/api-util/CommonRequest';
import CustomLoader from '../custom-modules/CustomLoader';
function Confirmation({wallet,closeWallet,walletDeleteId ,onSuccess}){
    const alert = useAlert();
    const [deleteData, setDeleteData] = useState(null);
    const [pending, setPending] = useState(false);


    const deleteConfirmation = async () => {
        let variables = {
            id: DataHandler.isIntegerValue(walletDeleteId)
        };
        setPending(true);
        let request = getRequestForApi(Global.DELETE_WALLET_NAME, variables, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                alert.show("Wallet name deleted successfully.", { type: alertTypes.SUCCESS }); 
                setDeleteData();
                closeWallet();
                onSuccess()
            };
            setPending(false);
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false)
        })
    };
    
    return(
        <>
         {<CustomLoader pending={pending}/>}
        <div className={`modal ${wallet ? 'show' : 'fade'} bd-example-modal-sm page-over`} id="deleteToken45" tabIndex="1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <p>
                        Are you sure you want to delete this wallet name?
                        </p>
                    </div>
                    <div className="modal-footer yesNoBtn">
                        <button type="button" className="btn btn-secondary yesBtn" data-dismiss="modal"  onClick={()=>deleteConfirmation()}>Yes</button>
                        <button type="button" className="btn btn-primary noBtn" data-dismiss="modal" onClick={()=>closeWallet(null)}>No</button>
                    </div>
                </div>  
            </div>
        </div>
        </>
    )
}
export default  Confirmation;