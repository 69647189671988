import React, { useState } from 'react';
import CustomLoader from '../custom-modules/CustomLoader';
import * as CustomInput from "../../utility-files/custom-input/CustomInput"
import * as DataHandler from "../../utility-files/data-util/DataHandler"
import * as Global from "../../constants/Global"
import { alertTypes, errorMessages } from '../../utility-files/helper-function/HelperFunction';
import { useAlert } from 'react-alert';
import { callHttpRequest, methodType } from '../../utility-files/api-util/HttpRequest';
import { getRequestForApi } from '../../utility-files/api-util/CommonRequest';

function Invitesend (props) {
    const{isShow,onClose}=props
    const alert = useAlert();
    const [pending,setPending]=useState(false)
    const [inviteData,setInviteData]=useState(null)
    const[submitted,setSubmitted]=useState(false)
    
    const InviteEvent= async()=>{
        setPending(true)
        if (inviteData?.email === "") {
            setSubmitted(true);
            return;
        }
        if ( !inviteData?.isEmailValid ) {
            setSubmitted(true);
            return;
        }
        let variable = {}
        variable.email = inviteData?.["email"];
        let request = getRequestForApi(Global.INVITE_SEND, variable, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                alert.show("Invitation sent successfully to join as Admin", { type: alertTypes.SUCCESS });
                setInviteData(null);
                setSubmitted(false);
                setPending(false)
                onClose();
            };
    
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false)
        })
    }

    const disableSubmit = () => {
        if (!inviteData?.email) {
            return true;
        };
    };
    
    return(
        <>
        {<CustomLoader pending={pending} />}
        <div className={`modal ${isShow ? "show" : "fade"} bd-example-modal-sm`}id="InviteSend" tabIndex="-1" aria-labelledby="InviteSend" aria-hidden="false">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalCenterTitle">Invite</h5>
                        <button type="button" className="small btn-close close-name" data-bs-dismiss="modal"
                            aria-label="Close" onClick={()=>{onClose();setInviteData(null)}} ></button>
                    </div>
                    <div className="modal-body">
                        <CustomInput.Email
                            setData={setInviteData}
                            keyName={'email'}
                            className={'form-control'}
                            id="email-name"
                            placeholder={"Enter your email name"}
                            value={DataHandler.isStringValue(inviteData?.['email'])}
                        />
                       {submitted && <p className='warninig'>{DataHandler.isStringValue(inviteData?.email) === '' ? errorMessages.REQUIRED_FIELD : !inviteData?.isEmailValid ? errorMessages.ENTER_VALID_EMAIL : ''}</p> } 

                    </div>
                    <div className="modal-footer">
                        <button type="button" className={`btn btn-primary ${disableSubmit() ? 'disable-button' : " "}`} data-bs-dismiss="modal" onClick={()=>InviteEvent()}>Send invite</button>

                    </div>
                </div>
            </div>
        </div>
    </>
    )
} 
export default Invitesend;
