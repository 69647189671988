import React, { useEffect, useState } from 'react'
import * as CustomInput from '../../utility-files/custom-input/CustomInput'
import { callHttpRequest, methodType } from '../../utility-files/api-util/HttpRequest';
import * as Global from '../../constants/Global';
import * as DataHandler from '../../utility-files/data-util/DataHandler'
import { alertTypes, sourceName } from '../../utility-files/helper-function/HelperFunction';
import { useAlert } from 'react-alert';
import { errorMessages } from '../../utility-files/helper-function/HelperFunction';
import { getRequestForApi } from '../../utility-files/api-util/CommonRequest';
import CustomLoader from '../custom-modules/CustomLoader';

function UserToken({ userId, onClose, onSuccess, source, freeTokens }) {
    const alert = useAlert();
    const [tokens, setTokens] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [pending, setPending] = useState(false);

    useEffect(() => {
        setSubmitted(false)
    }, [tokens])

    const usersToken = async (e) => {
        e.preventDefault();
        let variables = {
            userId: DataHandler.isStringValue(userId),
            noOfTokens: DataHandler.isIntegerValue(tokens?.tokenNumber)
        };
        if (!tokens?.tokenNumber || tokens?.tokenNumber === '0') {
            setSubmitted(true);
            return;
        }
        setPending(true);
        let request = getRequestForApi(Global.USER_TOKEN, variables, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                alert.show(response?.data?.response?.data, { type: alertTypes.SUCCESS });
                setTokens(null);
                setSubmitted(false);
                onSuccess();
                onClose();
            };
            setPending(false);
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false)
        })
    };

    const increaseSubscriptionPeriod = async (e) => {
        e.preventDefault();
        if (!tokens?.tokenNumber || tokens?.tokenNumber === '0') {
            setSubmitted(true);
            return;
        }
        setPending(true);
        let variables = {
            userId: DataHandler.isStringValue(userId),
            months: DataHandler.isIntegerValue(tokens?.tokenNumber)
        };
        let request = getRequestForApi(Global.INCREASE_SUBSCRIPTION, variables, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                alert.show(response?.data?.response?.data, { type: alertTypes.SUCCESS });
                setTokens(null);
                setSubmitted(false);
                onSuccess();
                onClose();
            };
            setPending(false);
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false)
        })
    }

    const handleSave = async (e) => {
        if (source === sourceName.businessLite && !freeTokens) {
            increaseSubscriptionPeriod(e);
        } else {
            usersToken(e);
        };
    };

    return (
        <>
            {<CustomLoader pending={pending} />}
            <div className={`modal ${userId ? "show" : "fade"}`} id={userId} tabIndex="-1" role="dialog" aria-labelledby={userId} aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header hide-border">
                            <div className="modal-head">
                                <h5 className="modal-title" id="exampleModalLabel">{source === sourceName.businessLite ? (freeTokens ? 'Free Single Use Tokens' : 'Free Months') : 'Free Tokens'}</h5>
                            </div>
                        </div>
                        <div className="form-group px-4">
                            <CustomInput.Number
                                setData={setTokens}
                                keyName={"tokenNumber"}
                                minValue="1"
                                maxValue={source === sourceName.businessLite ? '12' : '99999'}
                                value={DataHandler.isStringValue(tokens?.['tokenNumber'])}
                                className="form-control default-font"
                                id="tokenNumber"
                                placeholder={`Enter ${source === sourceName.businessLite ? (freeTokens ? "number of tokens" : "number of months") : "number of tokens"}`}
                            />
                            {submitted && <p className='warninig'>{DataHandler.isStringValue(tokens?.tokenNumber) === '' ? errorMessages.REQUIRED_FIELD : !tokens?.noOfTokens ? errorMessages.TOKENS : ''}</p>}
                        </div>
                        <div className="modal-footer hide-border mt-3">
                            <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal" onClick={() => { onClose(); setTokens(null); }}>Cancel</button>
                            <button type="button" className="btn  btn-primary btn-sm" data-bs-dismiss="modal" onClick={(e) => handleSave(e)} >Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UserToken
