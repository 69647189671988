import React, { useState } from "react";
import { useAlert } from "react-alert";
import * as Global from '../../constants/Global'
import { callHttpRequest, methodType } from "../../utility-files/api-util/HttpRequest";
import { getRequestForApi } from "../../utility-files/api-util/CommonRequest";
import { isStringValue } from "../../utility-files/data-util/DataHandler";
import { alertTypes } from "../../utility-files/helper-function/HelperFunction";
import CustomLoader from '../custom-modules/CustomLoader';
import * as DataHandler from "../../utility-files/data-util/DataHandler";
import * as CustomInput from "../../utility-files/custom-input/CustomInput";



function AddName(props) {

    const alert = useAlert();
    const { isShow, onClose, onSuccess } = props
    const [nameOfWallet, setNameOfWallet] = useState(null)
    const [pending, setPending] = useState(false)

    const handleSubmit = () => {
        walletAddName();
    };

    const walletAddName = async () => {
        setPending(true);
        let variables = {
            name: isStringValue(nameOfWallet?.name)
        };
        let request = getRequestForApi(Global.ADD_WALLET_NAME, variables, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                alert.show("Wallet name added successfully.", { type: alertTypes.SUCCESS });
                setNameOfWallet();
                setPending(false);
                onSuccess()
            };
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false)
        })
    };

    return (
        <>
            {<CustomLoader pending={pending} />}
            <div className={`modal ${isShow ? "show" : "fade"} bd-example-modal-sm`} id="AddName" tabIndex="-1" aria-labelledby="AddName" aria-hidden="false" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Add Wallet Name</h5>
                            {/* <button type="button" className="small btn-close wallet-name-modal" data-bs-dismiss="modal"
                                aria-label="Close"  onClick={() =>onClose()}></button> */}
                        </div>
                        <div className="modal-body">
                            <CustomInput.Text
                                setData={setNameOfWallet}
                                keyName={'name'}
                                className={'form-control'}
                                id="name"
                                maxValue={50}
                                placeholder={"Enter your name"}
                                value={DataHandler.isStringValue(nameOfWallet?.['name'])}
                                isrequired={true}
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal" onClick={() => { setNameOfWallet(null); onClose() }}>Cancel</button>
                            <button type="button" className={`btn btn-primary ${!nameOfWallet?.name ? 'disable-button' : ''}`} data-bs-dismiss="modal" onClick={() => { handleSubmit(); onClose() }}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default AddName;