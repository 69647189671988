import React, { useEffect, useState } from "react";
import { alertTypes, roleTypes } from "../../utility-files/helper-function/HelperFunction";
import { getRequestForApi } from "../../utility-files/api-util/CommonRequest";
import * as Global from '../../constants/Global'
import { callHttpRequest, methodType } from "../../utility-files/api-util/HttpRequest";
import CustomLoader from "../custom-modules/CustomLoader";
import * as CustomInput from '../../utility-files/custom-input/CustomInput';
import * as DataHandler from '../../utility-files/data-util/DataHandler';
import { useAlert } from "react-alert";


function AttachmentConfig({ showModal, onClose, userType }) {
    const alert = useAlert();
    const [attachmentConfig, setAttachmentConfig] = useState(null);
    const [pending, setPending] = useState(false);

    useEffect(() => {
        if (showModal) {
            getAttachmentConfig();
        }
    }, [showModal]);

    const getAttachmentConfig = async () => {
        setPending(true);
        let variables = {}, request, res;
        variables.configurationFor = userType === roleTypes.MANUFACTURER ? roleTypes.MANUFACTURER : roleTypes.CUSTOMER;
        variables.orgId = DataHandler.isStringValue(showModal?.orgId);
        request = getRequestForApi(Global.READ_PLAN_CONFIG, variables, methodType.POST)
        await callHttpRequest(request).then(async (response) => {
            if (response?.status === 201) {
                res = response?.data?.response?.data
                setAttachmentConfig(res);
            };
            setPending(false);
        }).catch((err) => {
            setPending(false);
        });
    };

    const createConfiguration = async () => {
        setPending(true)
        let variables = {};
        variables.configurationFor = userType === roleTypes.MANUFACTURER ? roleTypes.MANUFACTURER : roleTypes.CUSTOMER
        variables.maxImageCount = DataHandler.isIntegerValue(attachmentConfig?.maxImageCount)
        variables.maxFileCount = DataHandler.isIntegerValue(attachmentConfig?.maxFileCount);
        variables.maxImageSizeInKb = DataHandler.isIntegerValue(attachmentConfig?.maxImageSizeInKb);
        variables.maxFileSizeInKb = DataHandler.isIntegerValue(attachmentConfig?.maxFileSizeInKb);
        variables.orgId = DataHandler.isStringValue(showModal?.orgId);
        let request = getRequestForApi(Global.ATTACHMENT_CONFIG, variables, methodType.POST)
        await callHttpRequest(request).then(async (response) => {
            if (response?.status === 201) {
                if (response?.data?.error === 201) {
                    alert.show("File configuration save successfully.", { type: alertTypes.SUCCESS });
                    getAttachmentConfig();
                    setAttachmentConfig();
                    onClose();
                } else {
                    alert.show(response?.data?.response?.data, { type: alertTypes.ERROR });
                };
            };
            setPending(false);
        }).catch((err) => {
            setPending(false);
        });
    };

    const handleClose = () => {
        onClose()
    };

    const disableSubmit = () => {
        if (!attachmentConfig?.maxImageCount || !attachmentConfig?.maxFileCount || !attachmentConfig?.maxImageSizeInKb || !attachmentConfig?.maxFileSizeInKb) {
            return true;
        };
    };

    return (
        <>
            {<CustomLoader pending={pending} />}
            <div className={`modal ${showModal ? "show" : "fade"}`} id="attachmentConfig" tabIndex="-1" aria-labelledby="attachmentConfig" aria-hidden="false" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Attachment Configuration</h5>
                            <button type="button" className="small btn-close close-attachmentConfig-modal" data-bs-dismiss="modal"
                                aria-label="Close" onClick={() => { handleClose() }}></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Maximum image count:</label>
                                <CustomInput.Number
                                    setData={setAttachmentConfig}
                                    keyName={'maxImageCount'}
                                    className="form-control default-font"
                                    id="maxImage-Count"
                                    minValue="1"
                                    placeholder={"Enter Image Count"}
                                    value={DataHandler.isStringValue(attachmentConfig?.['maxImageCount'])}
                                    isrequired={true}
                                    maxValue={'10'}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Maximum file count:</label>

                                <CustomInput.Number
                                    setData={setAttachmentConfig}
                                    keyName={'maxFileCount'}
                                    className="form-control default-font"
                                    id="maxFile-Count"
                                    minValue="1"
                                    placeholder={"Enter file Count"}
                                    value={DataHandler.isStringValue(attachmentConfig?.['maxFileCount'])}
                                    isrequired={true}
                                    maxValue={'10'}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Maximum image size in kb:</label>
                                <CustomInput.Number
                                    setData={setAttachmentConfig}
                                    keyName={'maxImageSizeInKb'}
                                    className="form-control default-font"
                                    id="maxImage-SizeInKb"
                                    minValue="1"
                                    placeholder={"Enter image size in kb"}
                                    value={DataHandler.isStringValue(attachmentConfig?.['maxImageSizeInKb'])}
                                    isrequired={true}
                                    maxValue={'9999'}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Maximum file size in kb:</label>
                                <CustomInput.Number
                                    setData={setAttachmentConfig}
                                    keyName={'maxFileSizeInKb'}
                                    className="form-control default-font"
                                    id="maxFile-SizeInKb"
                                    minValue="1"
                                    placeholder={"Enter file size in kb"}
                                    value={DataHandler.isStringValue(attachmentConfig?.['maxFileSizeInKb'])}
                                    isrequired={true}
                                    maxValue={'9999'}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className={`btn btn-primary ${disableSubmit() ? 'disable-button' : " "}`} data-bs-dismiss="modal" onClick={() => { createConfiguration(); }}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AttachmentConfig;