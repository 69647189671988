import React, { useState } from 'react'
import { useAlert } from 'react-alert';
import { alertTypes, downloadXMLfile, exportUserFileName, sourceName } from '../../utility-files/helper-function/HelperFunction';
import * as Global from '../../constants/Global'
import ApiHelper from '../../config/ApiHelper';
import CustomLoader from '../custom-modules/CustomLoader';

function ExportUsers({ isShow, onSuccess, onClose, source}) {
   
    const [pending, setPending] = useState(false);
    const alert = useAlert();
    const callExportUsersApi = async () => {
        let requestUrl, fileName;
        setPending(true);
        requestUrl = (source === sourceName.businessLite) ? Global.EXPORT_BUSINESSLITE_USERS : Global.EXPORT_CONSUMERLITE_USERS;
        fileName = exportUserFileName(source, '_users_list');
        await ApiHelper.get(requestUrl, { responseType: 'blob' }).then((response) => {
            if (response?.status === 200) {
                downloadXMLfile(response?.data, fileName, onClose());
                onSuccess();
                alert.show('File downloaded successfully', { type: alertTypes.SUCCESS });
            };
            setPending(false);
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false);
        });
    };

    return (
        <>
       
            {<CustomLoader pending={pending} />}
            <div className={`modal ${isShow ? "show" : "fade"} bd-example-modal-sm`} id="modal" tabIndex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header hide-border d-flex justify-content-between align-items-center">
                            <div>
                                <p className='mb-0'>Are you sure you want to export users list?</p>
                            </div>
                            {/* <div className="close" onClick={() => onClose()}>
                                <button type="button" className="btn-close" aria-label="Close" />
                            </div> */}
                        </div>
                        {/* <div className="modal-body">

                        </div> */}
                        <div className="modal-footer d-flex justify-content-end mt-2">
                            <button type="button" className="btn btn-secondary" onClick={() => callExportUsersApi()}>Yes</button>
                            <button type="button" className="btn btn-primary" onClick={() => onClose()}>No</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExportUsers
