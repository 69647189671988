import React from "react";
function DeleteConfirm({onClose,onSubmit,showPopUp ,propsData,planScreen}){
    return(
        <>
           <div className={`modal ${showPopUp ? "show" : "fade"} bd-example-modal-sm`} id="modal" tabIndex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header hide-border d-flex justify-content-between align-items-center">

                           
                           <div>
                                <p className='mb-0'>{ planScreen ? "Are you sure, you want delete this subscription ?" : "Are you sure, you want delete this plan ?"}</p>
                            </div>
                            {/* <div className="close" onClick={() => onClose()}>
                                <button type="button" className="btn-close" aria-label="Close" />
                            </div> */}
                        </div>
                        {/* <div className="modal-body">

                        </div> */}
                        <div className="modal-footer d-flex justify-content-end mt-2">
                            <button type="button" className="btn btn-secondary" onClick={() => onSubmit(propsData?.id)}>Yes</button>
                            <button type="button" className="btn btn-primary" onClick={() => onClose()}>No</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DeleteConfirm