import React from 'react'

function GrantAdmin(props) {
    const { onClose, onSubmit, isShowPop, message } = props;
    return (
        <div className={`modal ${isShowPop ? "show" : "fade"} bd-example-modal-sm`} id="modal" tabIndex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
            <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-header hide-border d-flex justify-content-between align-items-center">
                        <div>
                            <p className='mb-0'>{message}</p>
                        </div>
                    </div>
                    <div className="modal-footer d-flex justify-content-end mt-2">
                        <button type="button" className="btn btn-secondary" onClick={() => onSubmit()}>Yes</button>
                        <button type="button" className="btn btn-primary" onClick={() => onClose()}>No</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GrantAdmin;
