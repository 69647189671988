import React, { useEffect, useState } from 'react'
import { IMAGE_TYPES } from '../../utility-files/default-values/DefaultValues';
import { CallApiHandler } from '../../utility-files/custom-hooks/CallApiHandler';
import * as Global from '../../constants/Global'
import CustomLoader from '../custom-modules/CustomLoader';
import CommonTable from '../../custom-ui/CommonTable';
import * as DataHandler from '../../utility-files/data-util/DataHandler'
import { roleTypes, sourceName } from '../../utility-files/helper-function/HelperFunction';
import Confirmation from '../common-module/Confirmation';
import UserToken from '../custom-popup-modal/UserToken';
import PlansModal from '../common-module/PlansModal';
import CardSection from '../common-module/CardSection';
import ExportUsers from '../common-module/ExportUsers';
import { getDateFormat, getTimeFormat } from '../../utility-files/date-util/DateHandling';
import AttachmentConfig from '../custom-popup-modal/AttachmentConfig';
import CustomConfirmation from '../custom-modules/CustomConfirmation';
import { getRequestForApi } from '../../utility-files/api-util/CommonRequest';
import { callHttpRequest, methodType } from '../../utility-files/api-util/HttpRequest';
import { useAlert } from 'react-alert';
import ViewTokens from './ViewTokens';
import { encryptData } from '../../utility-files/data-encryption-util/DataEncryption';
import TransactionHistory from '../common-module/TransactionHistory';

function ConsumerLite() {
    const alert = useAlert();
    const [userDetails, setUserDetails] = useState(null);
    const [usersList, setUsersList] = useState(null);
    const [userData, setUserData] = useState(null);
    const [isShow, setIsShow] = useState(false);
    const [pending, setPending] = useState(false);
    const [usersPopup, setUsersPopup] = useState(false);
    const [tokensPopup, setTokensPopup] = useState(false);
    const [userIds, setUserIds] = useState(null);
    const [userTokens, setUserToken] = useState(null);
    const [refetch, setRefetch] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [userTokensPopup, setUserTokensPopup] = useState(null);
    const [showPopUp, setShowPopUp] = useState(null);
    const [transactionModal, setTransactionModal] = useState(false)

    const modal = () => {
        setShowModal(!showModal);
    };

    const handleModal = (row) => {
        setTransactionModal(!transactionModal)
        setUserData(row);
    };

    const onClose = () => {
        setShowPopUp(null);
    };

    const handleUserStatus = async () => {
        setPending(true);
        const userStatus = DataHandler.isBooleanValue(showPopUp?.isActive);
        const variables = {
            userId: showPopUp?.id,
            statusId: userStatus ? 0 : 1
        };
        let request = getRequestForApi(Global.HANDLE_USER_STATUS, variables, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                alert.show(`user ${userStatus ? 'deactivated' : 'activated'} successfully`);
                onClose();
                getConsumerLiteItemUsers();
            };
            setPending(false);
        }).catch((err) => {
            console.log("err", err);
            setPending(false);
        });
    };

    const handleModel = () => {
        setIsShow(!isShow);
    };

    useEffect(() => {
        getConsumerLiteItemUsers();
    }, []);

    const onSuccess = () => {
        setRefetch(true);
        getConsumerLiteItemUsers();
    };

    const getConsumerLiteItemUsers = async () => {
        setPending(true);
        const response = await CallApiHandler(Global.CONSUMERLITE_USERS, undefined);
        const responseData = response?.data;
        if (responseData) {
            setUserDetails(responseData);
            setUsersList(responseData);
            setUserIds(null);
        };
        setPending(false);
    };

    const itemColumns = [
        {
            name: <th>Name</th>,
            selector: row => row?.displayName,
            sortable: true,
        },
        {
            name: <th>Email</th>,
            selector: row => row?.email,
            sortable: true,
            cell: (row) => (
                <div>
                    <div>{DataHandler.isStringValue(row?.email)}</div>
                    <div>{DataHandler.isStringValue(row?.contactNumber)}</div>
                </div>
            ),
        },

        {
            name: <th>Contact Number</th>,
            selector: row => row?.contactNumber,
            sortable: true,
        },
        {
            name: <th>Wallet Name</th>,
            selector: row => row?.walletName,
            sortable: true,
        },
        {
            name: <th>Zip Code</th>,
            selector: row => row?.zipCode,
            sortable: true,
        },
        {
            name: <th>Total Token Generated</th>,
            selector: row => row?.tokensGenerated,
            sortable: true,
            cell: (row) => (
                <div className="card-body py-0">
                    <p className="card-text">{DataHandler.isIntegerValue(row?.tokensGenerated)} <span>/{DataHandler.isIntegerValue(row?.totalToken)}</span></p>
                </div>
            ),
        },
        {
            name: <th>Creation Date</th>,
            selector: row => row?.CreateDate,
            sortable: true,
            cell: row => (<>{getDateFormat(row?.CreateDate)}
            </>)
        },
        {
            name: <th>Activity Date</th>,
            selector: (row) => row?.activitydate,
            sortable: true,
            cell: row => (<>{getDateFormat(row?.activitydate)} {getTimeFormat(row?.activitydate)}
            </>)
        },
        {
            name: <th>Status</th>,
            selector: (row) => row?.isActive,
            sortable: true,
            cell: row => (
                <span data-tooltip={DataHandler.isStringValue(row?.isActive)}>
                    <span className='ellipsis'>
                        {DataHandler.isStringValue(row?.status)?.toLocaleLowerCase() === 'active' ?
                            <span className="logged-in">●</span> :
                            DataHandler.isStringValue(row?.status)?.toLocaleLowerCase() === 'inactive' ?
                                <span className="logged-out">●</span> :
                                <span className="pending-out">●</span>}
                    </span>
                </span>),
            center: true
        },
        {
            name: <th>Action</th>,
            selector: row => row?.tokensGenerated,
            sortable: true,
            cell: row => (
                <td className="actionbutton">
                    {row?.status && row?.status !== "Pending" ?
                        <div className="hasDropdown">
                            <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-more-vertical" color="rgba(107, 114, 128, 1)" pointerEvents="none"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg></span>
                            <div className="dropDown">
                                <ul className="dropdown">
                                    <li onClick={() => setUserToken(row?.id)} className={DataHandler.isBooleanValue(row?.isActive) ? '' : 'disable-button'}>
                                        <img src={IMAGE_TYPES.FREE_TOKEN} alt="" />
                                        <a className="openModel">Free Token</a>
                                    </li>
                                    <li className={row?.availableTokenCount === 0 ? "disable-button" : ""} onClick={() => selectedRowId(row)}>
                                        <img src={IMAGE_TYPES.DOWNLOAD_TOKEN} alt="" />
                                        <a className="openModel">Download</a>
                                    </li>
                                    <li onClick={() => setShowPopUp(row)}>
                                        <img src={IMAGE_TYPES.REFRESH_ICON} alt="" />
                                        <a className="openModel">{DataHandler.isStringValue(row?.isActive) ? 'Deactivate' : 'Activate'}</a>
                                    </li>
                                    <li className={row?.availableTokenCount === 0 ? "disable-buttton" : ""} onClick={() => setUserTokensPopup(encryptData(row?.id))}>
                                        <img src={IMAGE_TYPES.FREE_TOKEN} alt="" />
                                        <a className="openModel">View Tokens</a>
                                    </li>
                                    <li className={row?.totalToken === 0 ? "disable-buttton" : ""} onClick={() => handleModal(row)}>
                                        <img src={IMAGE_TYPES.FREE_TOKEN} alt="" />
                                        <a className="openModel">Transaction History</a>
                                    </li>
                                    {/* <li className={row?.tokensGenerated === 0 ? "disable-button" : ""}>
                                    <img src={IMAGE_TYPES.CLEAR_ALL_TOKEN} alt="" />
                                    <a className="openModel">Clear All Tokens</a>
                                </li> */}
                                </ul>
                            </div>
                        </div>
                        : ""}
                </td>
            ),
        }
    ];

    const selectedRowId = ({ id }) => {
        let selectedUsers = {};
        selectedUsers.userIds = [id];
        setUserIds(selectedUsers);
        exportTokensPopup();
    };

    const onSelectedRowsChange = ({ selectedRows }) => {
        let selectedUsers = {};
        selectedUsers.userIds = [];
        for (let index = 0; index < selectedRows?.length; index++) {
            let userId = selectedRows?.[index]["id"]
            selectedUsers.userIds[index] = userId;
        };
        setUserIds(selectedUsers);
    };

    const exportTokensPopup = (props) => {
        setTokensPopup(!tokensPopup);
        if (props === 'close') {
            setUserIds(null)
        };
    };

    const exportUsersPopup = () => {
        setUsersPopup(!usersPopup);
    };

    const tokenShow = () => {
        setUserToken(!userTokens);
    };

    const handleSearchData = (value) => {
        const searchData = usersList.filter(obj => obj?.displayName?.toLocaleLowerCase()?.includes(value)
            || obj?.email?.toLocaleLowerCase()?.includes(value) || obj?.contactNumber?.toLocaleLowerCase()?.includes(value)
            || obj?.walletName?.toLocaleLowerCase()?.includes(value) || obj?.zipCode?.toLocaleLowerCase()?.includes(value)
            || obj?.tokensGenerated?.toString()?.toLocaleLowerCase()?.includes(value)
        );
        if (searchData && value) {
            setUserDetails(searchData)
        } else {
            setUserDetails(usersList);
        };
    };

    return (
        <>
            {<CustomLoader pending={pending} />}
            <CardSection source={sourceName.consumerLite} refetch={refetch} setRefetch={setRefetch} />

            <section className="table-section-listing pt-0">
                <div className="row table-row-padding justify-content-end">
                    {/* <div className="col-lg-4 d-flex justify-content-initial gap-4">
                    </div> */}
                    <div className="col-lg-12">
                        <div className="input-group rounded d-flex justify-content-end gap-4">
                            <div className="input-group mb-3 w-25">
                                <input
                                    type="search"
                                    className="form-control search-bar-border"
                                    placeholder="Search ......"
                                    aria-label="Recipient's username"
                                    onChange={(event) => handleSearchData(event.target.value.toLocaleLowerCase())}
                                    autoComplete="off"
                                />
                            </div>
                            <div onClick={() => exportUsersPopup()}>
                                <button type="button" className="btn btn-primary d-flex align-items-center">
                                    <img
                                        className="icon-images-table"
                                        src={IMAGE_TYPES.PEOPLENEW_LOGO}
                                        alt=""
                                    />
                                    Export Users
                                </button>
                            </div>
                            <div
                                className={DataHandler.isArrayValue(userIds?.userIds).length !== 0 ? "" : "disable-button"}
                                onClick={() => exportTokensPopup()}
                            >
                                <button type="button" className="btn btn-primary d-flex align-items-center"><img className="icon-images-table"
                                    src={IMAGE_TYPES.COIN} alt="" />Export Tokens
                                </button>
                            </div>
                            <div>
                                <button type="button" className="btn btn-primary " onClick={handleModel}><img className="icon-images-table"
                                    src={IMAGE_TYPES.SETTING_NEW_LOGO} alt="" />Plan Configuration</button>
                            </div>

                            <div>
                                <button
                                    type="button"
                                    className="btn btn-primary "
                                    onClick={modal}
                                >
                                    <img
                                        className="icon-images-table"
                                        src={IMAGE_TYPES.SETTING_NEW_LOGO}
                                        alt=""
                                    />
                                    Attachment Configuration
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='admin-listing-table'>
                    <CommonTable
                        fixedHeader
                        fixedHeaderScrollHeight={"50vh"}
                        data={userDetails}
                        columns={itemColumns}
                        selectableRows
                        onSelectedRowsChange={onSelectedRowsChange}
                        clearSelectedRows={pending}
                    />
                </div>
            </section>

            <PlansModal isShow={isShow} onClose={handleModel} source={sourceName.consumerLite} userType={roleTypes.CUSTOMER} />
            <UserToken userId={userTokens} onClose={tokenShow} onSuccess={onSuccess} />
            <Confirmation isShow={tokensPopup} userIds={DataHandler.isArrayValue(userIds)} onSuccess={getConsumerLiteItemUsers} onClose={exportTokensPopup} source={sourceName.consumerLite} />
            <ExportUsers isShow={usersPopup} onSuccess={getConsumerLiteItemUsers} onClose={exportUsersPopup} source={sourceName.consumerLite} />
            <AttachmentConfig showModal={showModal} onClose={modal} userType={roleTypes.CUSTOMER} />
            <CustomConfirmation onClose={onClose} onSubmit={handleUserStatus} showPopUp={showPopUp} message={`Are you sure you want to ${DataHandler.isBooleanValue(showPopUp?.isActive) ? 'deactivate' : 'activate'} this user?`} />
            <ViewTokens showPopUp={userTokensPopup} setUserTokensPopup={setUserTokensPopup} />
            <TransactionHistory usersList={usersList} transactionModal={transactionModal} onClose={handleModal} onSuccess={getConsumerLiteItemUsers} setUsersDetails={setUserData} userDetails={userData} />
        </>
    )
}

export default ConsumerLite