
const $ = window.jQuery = require('jquery')


// $(document).on("click", "#tripleDots", function () {
//   $('.dropDown').removeClass('dropDown-show');
//   let parentDiv = $(this).closest('.hasDropdown');
//   let dropdownId = parentDiv.find('.dropDown').attr('id');
//   $(`#${dropdownId}`).addClass('dropDown-show');
// });

$(document).on('click', '.hasDropdown', function (e) {
  if ($(".hasDropdown").not(this).hasClass('open')) {
    $(".hasDropdown").removeClass('open');
  }
  if (!$(this).hasClass('open')) {
    $(this).addClass('open');
  } else {
    $(".hasDropdown").removeClass('open');
  }
  e.stopPropagation();
});

$(document).on('click', function () {
  $(".hasDropdown").removeClass('open');
});

$(document).on('click', '.profile-image', function () {
  $('#SideNavToggle').modal('show');
});

$(document).on('click', '.profile-Show', function () {
  $('#Profile').modal('show');
  $('#SideNavToggle').modal('hide');
});

// $(document).on('click', '.btn-close-profile', function () {
//   $('#SideNavToggle').modal('show');
// });

$(document).on('click', '.change_name', function () {
  $('#ChangeName').modal('show');
  $('#Profile').modal('hide');
});

$(document).on('click', '.change_password', function () {
  $('#ChangePassword').modal('show');
  $('#Profile').modal('hide');
});

$(document).on('click', '.profile_image', function () {
  $('#ImageUpload').modal('show');
  $('#Profile').modal('hide');
});

$(document).on('click', '.close-name-modal', function () {
  $('#Profile').modal('show');
});

$(document).on('click', '.close-password-modal', function () {
  $('#Profile').modal('show');
});

$(document).on('click', '.image_upload_close', function () {
  $('#Profile').modal('show');
});



$(document).on('click', '.close-name-email', function () {
  $('#InviteSend').modal('show');

});


$(document).on('click', '.Attech_Config', function () {
  $('#AttechConfig').modal('hide');
});
// $(document).on('click', '.close', function () {
//   $('#ImageUpload').modal('show');
// });SideNavToggle

$(document).on('click', ".noBtn", function () {
  $("#deleteToken").removeClass('show');
});

