import React, { useEffect, useState } from 'react'
import CommonTable from '../../custom-ui/CommonTable'
import { getRequestForApi } from '../../utility-files/api-util/CommonRequest';
import { callHttpRequest, methodType } from '../../utility-files/api-util/HttpRequest';
import * as Global from '../../constants/Global'
import { Link, useLocation } from 'react-router-dom';
import { decryptData } from '../../utility-files/data-encryption-util/DataEncryption';
import { getDateFormat, getTimeFormat } from '../../utility-files/date-util/DateHandling';
import { isArrayValue, isIntegerValue, isStringValue } from '../../utility-files/data-util/DataHandler';
import Image from '../common-module/Image';
import { IMAGE_TYPES } from '../../utility-files/default-values/DefaultValues';
import CustomLoader from '../custom-modules/CustomLoader';
import { FILTER_LIST, navbar_links, path, userDetails } from '../../utility-files/helper-function/HelperFunction';

function ViewTokens(props) {
    const { showPopUp, setUserTokensPopup } = props;
    const [userList, setUserList] = useState(null);
    const [pending, setPending] = useState(false);
    const [flag, setFlag] = useState(1);
    const [timeFlag, setTimeFlag] = useState(2);
    const [tokenData, setTokenData] = useState(null);
    const [tokenHistory, setTokenHistory] = useState(null);
    const [tokenDetails, setTokenDetails] = useState(null);
    const [attachmentsList, setAttachmentsList] = useState(null);
    const userId = showPopUp;
    const location = useLocation();

    useEffect(() => { if (tokenData) { getTokenDetails(); } }, [tokenData]);

    useEffect(() => {
        if (showPopUp) {
            userListDashboard();
        }
    }, [showPopUp, flag, timeFlag]);

    const itemColumns = [
        {
            name: <th></th>,
            selector: row => row?.fileUrl,
            cell: row => (<>
                <div className="row token-detail-row">
                    <div className="col mb-0 item-img">
                        <Image className='item-img' src={isStringValue(row.fileUrl) || IMAGE_TYPES.SKULL} alt="" />
                    </div>
                </div>
            </>),
        },
        {
            name: <th>Item Name</th>,
            selector: row => row?.itemName,
            sortable: true,
            wrap: true
        },
        {
            name: <th>Item Description</th>,
            selector: row => row?.itemDescription,
            sortable: true,
            wrap: true
        },
        {
            name: <th>SKU Code</th>,
            selector: row => row?.skuCode,
            sortable: true,
            cell: row => (<>
                {isStringValue(row?.skuCode)}
            </>),
            wrap: true
        },
        {
            name: <th>Serial Number</th>,
            selector: row => row?.serialNo,
            sortable: true,
            cell: row => (<>
                {isStringValue(row?.serialNo)}
            </>),
            wrap: true
        },
        {
            name: <th>Token ID</th>,
            selector: row => row?.tokenId,
            sortable: true,
            cell: row => (<>
                {isStringValue(row?.tokenId)}
            </>),
            wrap: true
        },
        {
            name: <th>Owner</th>,
            selector: row => row?.ownerName,
            sortable: true,
            cell: row => (<div className='d-flex flex-column'>
                <div className='pt-1'>
                    {isStringValue(row?.ownerName)}
                </div>
                <div className='font-token-list pb-1'>
                    {isStringValue(row?.walletid)}
                </div>
            </div>),
            wrap: true
        },
        {
            name: <th>Creation Date</th>,
            selector: row => row?.createDate,
            sortable: true,
            cell: row => (<>
                {getDateFormat(isStringValue(row?.createDate))}
            </>),
        },
        {
            name: <th>Transfer Date</th>,
            selector: row => row?.transferDate,
            sortable: true,
            cell: row => (<>
                {getDateFormat(isStringValue(row?.transferDate))}
            </>),
        },
        {
            name: <th>Certificates</th>,
            selector: row => row?.isSingleTransfer ? "✅" : "",
            omit: flag === 1 || flag === 5,
            center: true,
        },
        {
            name: <th></th>,
            selector: row => row?.id,
            cell: row => (<>
                <Image src={IMAGE_TYPES.ARROW_RIGHT}
                    alt={row?.id} className='right_arrow'
                    onClick={() => setTokenData(row)}
                    onMouseOver={e => e.currentTarget.src = IMAGE_TYPES.SELECTED_ARROW}
                    onMouseOut={e => e.currentTarget.src = IMAGE_TYPES.ARROW_RIGHT}
                />
            </>),
        }
    ];

    const itemColumnsWithDetails = [
        {
            name: <th>Item Name</th>,
            selector: row => row?.itemName,
            sortable: true,
            cell: row => (<>
                {isStringValue(row?.itemName)}
            </>),
            width: '20%'
        },
        {
            name: <th>Token ID</th>,
            selector: row => row?.tokenId,
            sortable: true,
            cell: row => (<>
                {isStringValue(row?.tokenId)}
            </>),
            width: '30%'
        },
        {
            name: <th>Description</th>,
            selector: row => row?.itemDescription,
            sortable: true,
            width: '36%'
        },
        {
            name: <th></th>,
            selector: row => row?.id,
            cell: row => (<>
                {tokenData?.index !== row?.index &&
                    <Image src={IMAGE_TYPES.ARROW_RIGHT}
                        alt={row?.id} className='right_arrow'
                        onClick={() => setTokenData(row)}
                        onMouseOver={e => e.currentTarget.src = IMAGE_TYPES.SELECTED_ARROW}
                        onMouseOut={e => e.currentTarget.src = IMAGE_TYPES.ARROW_RIGHT}
                    />
                }
            </>),
            width: '10%'
        }
    ];

    const userListDashboard = async () => {
        setPending(true);
        let variables = {};
        variables.pageNo = 0;
        variables.perPageItem = 0;
        variables.userId = decryptData(userId);
        variables.index = "";
        variables.searchText = "";
        variables.flag = isIntegerValue(flag);
        variables.timeFlag = isIntegerValue(timeFlag);
        variables.createDate = "";
        variables.serialNumber = "";
        variables.skuCode = "";
        variables.owner = "";
        let request = getRequestForApi(Global.LIST_ALL_USER_ASSETS, variables, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                let responseData = response?.data?.response?.data;
                setUserList(responseData);
            };
            setPending(false);
        }).catch((err) => {
            console.log("err", err);
            setPending(false);
        })
    };

    const getTokenDetails = async () => {
        setPending(true);
        let variables = {};
        variables.index = isStringValue(tokenData?.index);
        variables.userId = isStringValue(decryptData(userId))
        let request = getRequestForApi(Global.GET_TOKEN_DETAILS, variables, methodType.POST);
        await callHttpRequest(request).then((response) => {
            if (response.status === 201) {
                let data = response?.data?.response?.data;
                if (data?.Notes?.length > 0) {
                    data.notes = data.Notes[data.Notes.length - 1];
                    data.notesIndex = data?.notes?.index;
                    if (userDetails()?.id === data.notes.createBy) {
                        data.notes = data.notes.notes;
                    } else {
                        data.notes = '';
                        data.notesIndex = '';
                    }
                } else {
                    data.notes = '';
                    data.notesIndex = '';
                };
                setTokenDetails(data);
                listAttachment(isStringValue(data?.itemIndex));
                getTokenHistory(isStringValue(tokenData?.index));
            };
            setPending(false);
        }).catch((err) => {
            console.log('err', err);
            setPending(false);
        });
    };

    const tokenHistoryColumns = <tr>
        <th scope="col">From</th>
        <th scope="col">To</th>
        <th scope="col">Transfer Date & Time</th>
    </tr>

    const tokenHistoryList = isArrayValue(tokenHistory)?.map((data, index) => {
        return <tr key={`history_${index}`}>
            <td className='text-break'>
                {data?.fromName}<br />
                {data?.fromWallet}
            </td>
            <td className='text-break'>
                {data?.toName}<br />
                {data?.toWallet}
            </td>
            <td className='text-break'>{getDateFormat(data?.tranferDate)} {getTimeFormat(data?.tranferDate)}</td>
        </tr>
    });

    const tokensAttachmentsList = isArrayValue(attachmentsList)?.map((data, index) => {
        return <>
            <div className='d-flex flex-column' key={`attachmentsList_${index}`}>
                <Link to={isStringValue(data?.fileUrl)}>
                    <Image src={data.fileName.includes('.pdf') ? IMAGE_TYPES.PDF_ICON : IMAGE_TYPES.XLXS} alt="" />
                    <p className='text-center py-1'>{isStringValue(data?.fileName).slice(0, 4) + isStringValue(data?.fileName).substring(isStringValue(data?.fileName).length - 4)}</p>
                </Link>
            </div>
        </>
    });

    const notesData = isStringValue(tokenDetails?.Notes[0]?.notes).split("\n")?.map((data) => {
        return isStringValue(data) !== '' && <span>{data}<br /></span>
    });

    const listAttachment = async (itemIndex) => {
        setPending(true);
        let variables = {
            itemIndex: isStringValue(itemIndex),
            fileType: isStringValue('attachments')
        };
        let request = getRequestForApi(Global.UPLOAD_LIST_ATTACHMENTS, variables, methodType.POST);
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                let res = response?.data?.response?.data;
                res?.sort(function (a, b) { return a.ordBy - b.ordBy });
                setAttachmentsList(isArrayValue(res));
            };
            setPending(false);
        }).catch((err) => {
            setPending(false);
            console.log("err", err)
        })
    };

    const getTokenHistory = async (tokenIndex) => {
        setPending(true);
        let variables = {
            assetIndex: tokenIndex
        };
        let request = getRequestForApi(Global.GET_TOKEN_HISTORY, variables, methodType.POST);
        await callHttpRequest(request).then((response) => {
            if (response.status === 201) {
                setTokenHistory(response?.data?.response?.data);
            };
            setPending(false);
        }).catch((err) => {
            console.log('err', err);
            setPending(false);
        });
    };

    const handleStates = () => {
        setUserList(null);
        setFlag(1);
        setTimeFlag(2);
        setUserTokensPopup(null);
        setTokenData(null);
    };

    const quick_filter = navbar_links.map((data, index) => {
        if (location.pathname === path.CONSUMER_LITE && data?.id !== 2) {
            return <li key={`quick_filter_${index}`}>
                <a href="#" className={`tablinks ${flag === data?.id && 'active'}`} onClick={() => setFlag(data?.id)}>{data?.name}</a>
            </li>
        }
        if (location.pathname !== path.CONSUMER_LITE) {
            return <li key={`quick_filter_${index}`}>
                <a href="#" className={`tablinks ${flag === data?.id && 'active'}`} onClick={() => setFlag(data?.id)}>{data?.name}</a>
            </li>
        }
    });

    return (
        <>
            {<CustomLoader pending={pending} />}
            <div className={`modal ${showPopUp ? "show" : "fade"} bd-example-modal-sm`} id="modal" tabIndex="-1" aria-labelledby="ChangeName" aria-hidden="false" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">User Tokens List</h5>
                            <button type="button" className="small btn-close" data-bs-dismiss="modal"
                                aria-label="Close" onClick={() => handleStates()}></button>
                        </div>
                        <div className="modal-body">
                            <div className='TokenDetails'>
                                <div className={isStringValue(tokenData?.index) ? 'Leftdetail' : ''}>
                                    <div className='main-div-tokens'>
                                        <div className="row table-row-padding p-0">
                                            <div className="col-lg-12 p-0">
                                                <div className="input-group rounded py-3 d-flex justify-content-between">
                                                    <div className="tabfilter filter2 tabfilter2">
                                                        <ul>
                                                            {quick_filter}
                                                            {/* <li>
                                                                <a href="#" className={`tablinks ${flag === 0 && 'active'}`} onClick={() => setFlag(0)}>All</a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className={`tablinks ${flag === 1 && 'active'}`} onClick={() => setFlag(1)}>Active</a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className={`tablinks ${flag === 2 && 'active'}`} onClick={() => setFlag(2)}>Unclaimed</a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className={`tablinks ${flag === 3 && 'active'}`} onClick={() => setFlag(3)}>Discarded</a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className={`tablinks ${flag === 4 && 'active'}`} onClick={() => setFlag(4)}>Transferred</a>
                                                            </li> */}
                                                        </ul>
                                                    </div>
                                                    <div className="actionRow">
                                                        <div className={`right float-end`}>
                                                            <select className="form-select" value={timeFlag} onChange={(e) => setTimeFlag(e.target.value)}>
                                                                {FILTER_LIST.map((data, index) => {
                                                                    return <option key={index} value={data?.id}>{data?.name}</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='admin-listing-table2'>
                                            <CommonTable
                                                columns={isStringValue(tokenData?.index) ? itemColumnsWithDetails : itemColumns}
                                                data={userList}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {isStringValue(tokenData?.index) && <div className="rightdetail" id="tokenRight">
                                <div className="holder">
                                    <div className="title">
                                        <div className='title-icon d-flex align-items-center gap-2'><img src={IMAGE_TYPES.TOKENTITLE} alt="" /> Token Details</div>
                                        <div className="icons gap-2">
                                            <a href='#' className='close_icon' onClick={() => setTokenData(null)}>
                                                <img src={IMAGE_TYPES.CLOSE_CIRCLE_IMAGE} alt="" />
                                            </a>
                                        </div>
                                    </div>

                                    <div className="detail noborder">
                                        <div className="row py-1 image-content-row">
                                            <div className="col-4">
                                                <Image src={isStringValue(tokenDetails?.Attachments?.[0]?.fileUrl || IMAGE_TYPES.SKULL)} className="item-image-radius img-fluid" alt="" style={{ height: "100px", width: "140px" }} />
                                            </div>
                                            <div className="col-8">
                                                <ul className="px-2 lh-lg">
                                                    {isStringValue(tokenDetails?.itemName) && <li className="lh-lg d-flex gap-2"><div><strong>Item Name:</strong></div><div><span>{isStringValue(tokenDetails?.itemName)}</span></div></li>}
                                                    {isStringValue(tokenDetails?.serialNo) && <li className="lh-lg d-flex gap-2"><div><strong>Serial No.:</strong></div><div><span>{isStringValue(tokenDetails?.serialNo)}</span></div></li>}
                                                    {isStringValue(tokenDetails?.tokenId) && <li className="lh-lg d-flex gap-2"><div><strong>Token ID:</strong></div> <div><span>{isStringValue(tokenDetails?.tokenId)}</span></div></li>}
                                                    {isStringValue(tokenDetails?.Notes[0]?.notes) && <li className="lh-lg d-flex gap-2"><div><strong>Notes:</strong></div><div className='notes-token-details'>{notesData}</div></li>}
                                                </ul>
                                            </div>
                                        </div>

                                        {isArrayValue(attachmentsList)?.length !== 0 && <div className="row py-1 image-content-row">
                                            <p className='heading-tt text-primary'>Attachments</p>
                                            <div className="file-attachments d-flex gap-4 overflow-auto">
                                                {tokensAttachmentsList}
                                            </div>
                                        </div>}

                                        {isArrayValue(tokenHistory)?.length !== 0 &&
                                            <div className="row p-3 image-content-row">
                                                <p className='heading-tt text-primary p-0'>Transfer History</p>
                                                <div className='table-token-detail transfer-history-table px-0'>
                                                    <table className="table table-striped table-bordered mb-0">
                                                        <thead>
                                                            {tokenHistoryColumns}
                                                        </thead>
                                                        <tbody>
                                                            {tokenHistoryList}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewTokens
