import React, { useEffect, useState } from 'react'
import * as CustomInput from '../../utility-files/custom-input/CustomInput';
import * as DataHandler from '../../utility-files/data-util/DataHandler';
import { alertMessages, alertTypes, roleTypes, sourceName } from '../../utility-files/helper-function/HelperFunction';
import { useAlert } from 'react-alert';
import CommonTable from '../../custom-ui/CommonTable';
import CustomLoader from '../custom-modules/CustomLoader';
import { getRequestForApi } from '../../utility-files/api-util/CommonRequest';
import { callHttpRequest, methodType } from '../../utility-files/api-util/HttpRequest';
import * as Global from '../../constants/Global'
import DeleteConfirm from './confirmationDelete';

const screen_type = {
    free_token: 'Free Token',
    plan: 'Plan',
    subscription: 'Subscription'
};

function PlansModal({ isShow, onClose, userType, source }) {
    const alert = useAlert();
    const [isEditData, setIsEditData] = useState(null);
    const [newPlan, setNewPlan] = useState(null);
    const [planScreen, setPlanScreen] = useState(screen_type.free_token);
    const [plansList, setPlansList] = useState(null);
    const [plansData, setPlansData] = useState(null);
    const [pending, setPending] = useState(false);
    const [freeTokens, setFreeTokens] = useState(false);
    const [deletePopUp, setDeletePopUp] = useState(false)
    const [deleteData, setDeleteData] = useState()
    const [pageSource, setPageSource] = useState(false);

    useEffect(() => {
        if (planScreen !== screen_type.free_token) {
            getPlansList();
        } else {
            readFreeToken();
        }
    }, [planScreen]);

    useEffect(() => {
        if (DataHandler.isBooleanValue(newPlan?.isTrialPlan)) {
            setNewPlan({ ...newPlan, amount: '0' })
        } else {
            setNewPlan({ ...newPlan, trialPeriod: 0, amount: '' })
        };
    }, [DataHandler.isBooleanValue(newPlan?.isTrialPlan)]);

    useEffect(() => {
        if (DataHandler.isBooleanValue(isEditData?.isTrialPlan)) {
            setIsEditData({ ...isEditData, amount: '' });
        } else {
            setIsEditData({ ...isEditData, trialPeriod: 0 });
        };
    }, [isEditData?.isTrialPlan]);

    useEffect(() => [
        readFreeToken()
    ], [isShow]);

    const handleSubmit = (event) => {
        if (planScreen !== screen_type.free_token) {
            if (DataHandler.isIntegerValue(isEditData?.id) !== 0 && event === 'update') {
                if (DataHandler.isBooleanValue(isEditData?.isTrialPlan) && DataHandler.isIntegerValue(isEditData?.trialPeriod) === 0) {
                    alert.show('please enter trial period', { type: alertTypes.ERROR });
                } else {
                    updatePlan();
                };
            } else {
                if (DataHandler.isBooleanValue(newPlan?.isTrialPlan) && DataHandler.isIntegerValue(newPlan?.trialPeriod) === 0) {
                    alert.show('please enter trial period', { type: alertTypes.ERROR });
                } else {
                    addPlan();
                };
            };
        } else {
            if (DataHandler.isStringValue(freeTokens?.freeTokens) === "") {
                alert.show('please enter a value', { type: alertTypes.ERROR });
                return;
            }
            addFreeToken();
        };
    };

    const handleClose = () => {
        setIsEditData(null);
        setPlanScreen(screen_type.free_token);
        setNewPlan(null);
        onClose();
    };

    const readFreeToken = async () => {
        setPending(true);
        let variables, request, requestURL;
        requestURL = Global.READ_FREE_TOKEN + (userType === roleTypes.MANUFACTURER ? roleTypes.MANUFACTURER : roleTypes.CUSTOMER);
        request = getRequestForApi(requestURL, variables, methodType.GET);
        await callHttpRequest(request).then(async (response) => {
            if (response?.status === 200 && response?.data?.error === 200) {
                setFreeTokens(response?.data?.response?.data)
            };
            setPending(false);
        }).catch((err) => {
            setPending(false);
        });
    };

    const getPlansList = async () => {
        setPending(true);
        let variables = {}, request, res, requestURL = Global.READ_PLAN;
        if (planScreen === screen_type.subscription) {
            variables.subscriptionId = 0;
            variables.subscriptionFor = userType === roleTypes.MANUFACTURER ? roleTypes.MANUFACTURER : roleTypes.CUSTOMER;
            requestURL = Global.READ_SUBSCRIPTION;
        } else {
            variables.planId = 0;
            variables.planFor = userType === roleTypes.MANUFACTURER ? roleTypes.MANUFACTURER : roleTypes.CUSTOMER;
        };
        request = getRequestForApi(requestURL, variables, methodType.POST)
        await callHttpRequest(request).then(async (response) => {
            if (response?.status === 201) {
                res = response?.data?.response?.data
                setPlansList(res);
                setPlansData(res);
                setIsEditData(null);
                setNewPlan(null);
            };
            setPending(false);
        }).catch((err) => {
            setPending(false);
        });
    };

    const addFreeToken = async () => {
        setPending(true);
        let variables = {}, request;
        variables.type = userType === roleTypes.MANUFACTURER ? roleTypes.MANUFACTURER : roleTypes.CUSTOMER;
        variables.freeTokens = DataHandler.isIntegerValue(freeTokens?.freeTokens);
        request = getRequestForApi(Global.ADD_UPDATE_FREE_TOKEN, variables, methodType.POST)
        await callHttpRequest(request).then(async (response) => {
            if (response?.status === 201 && response?.data?.error === 201) {
                alert.show(alertMessages.FREE_TOKENS_SUCCESS, { type: alertTypes.SUCCESS });
                setFreeTokens(null);
                setIsEditData(null);
                readFreeToken();
            };
            setPending(false);
        }).catch((err) => {
            setPending(false);
        });
    };
    
    const addPlan = async () => {
        setPending(true);
        let variables = {}, request, requestURL = Global.ADD_PLAN, showMessage = 'Plan added successfully';
        if (planScreen === screen_type.subscription) {
            variables.subscription = newPlan?.planName;
            variables.description = `${DataHandler.isIntegerValue(newPlan?.noOfTokens)?.toLocaleString()} Tokens for $${DataHandler.isIntegerValue(newPlan?.amount)?.toLocaleString()} per month`;
            variables.subscriptionFor = userType === roleTypes.MANUFACTURER ? roleTypes.MANUFACTURER : roleTypes.CUSTOMER;
            variables.isTrialPlan = DataHandler.isBooleanValue(newPlan?.isTrialPlan);
            variables.trialPeriod = DataHandler.isBooleanValue(newPlan?.isTrialPlan) ? DataHandler.isIntegerValue(newPlan?.trialPeriod) : 0;
            requestURL = Global.ADD_SUBSCRIPTION;
            showMessage = 'Subscription added successfully';
        } else {
            variables.planName = newPlan?.planName;
            variables.description = `${DataHandler.isIntegerValue(newPlan?.noOfTokens)?.toLocaleString()} Tokens for $${DataHandler.isIntegerValue(newPlan?.amount)?.toLocaleString()}`;
            variables.planFor = userType === roleTypes.MANUFACTURER ? roleTypes.MANUFACTURER : roleTypes.CUSTOMER;
        };
        variables.amount = newPlan?.amount;
        variables.noOfTokens = planScreen === screen_type.subscription ? DataHandler.isIntegerValue(newPlan?.noOfTokens) : newPlan?.noOfTokens;
        request = getRequestForApi(requestURL, variables, methodType.POST)
        await callHttpRequest(request).then(async (response) => {
            if (response?.status === 201 && response?.data?.error === 201) {
                alert.show(showMessage, { type: alertTypes.SUCCESS });
                getPlansList();
                setIsEditData(null);
                setNewPlan(null);
            } else {
                alert.show(response?.data?.response?.data, { type: alertTypes.ERROR });
            };
            setPending(false);
        }).catch((err) => {
            setPending(false);
        });
    };

    const updatePlan = async () => {
        setPending(true);
        let variables = {}, request, requestURL = Global.UPDATE_PLAN, errorMessage, subscription, planName, noOfTokens, amount, trialPeriod, isTrialPlan, showMessage = 'Plan updated successfully';
        subscription = DataHandler.isStringValue(isEditData?.subscription);
        planName = DataHandler.isStringValue(isEditData?.planName);
        noOfTokens = DataHandler.isIntegerValue(isEditData?.noOfTokens);
        amount = DataHandler.isStringValue(isEditData?.amount);
        trialPeriod = DataHandler.isIntegerValue(isEditData?.trialPeriod);
        isTrialPlan = DataHandler.isBooleanValue(isEditData?.isTrialPlan);
        if ((planScreen === screen_type.subscription && subscription === '') || (planScreen !== screen_type.subscription && planName === '') || noOfTokens === 0 || (planScreen === screen_type.subscription && isTrialPlan ? trialPeriod === 0 : (amount === '' || amount === '0'))) {
            if (subscription === '' || planName === '') {
                errorMessage = `please enter ${planScreen === screen_type.subscription ? 'subscription' : 'plan'} name`
            };
            if (noOfTokens === 0) {
                errorMessage = 'please enter number of tokens'
            };
            if (planScreen === screen_type.subscription && !isTrialPlan && (amount === '' || amount === '0')) {
                errorMessage = 'please enter amount'
            };
            if (planScreen !== screen_type.subscription && (amount === '' || amount === '0')) {
                errorMessage = 'please enter amount'
            };
            if (isTrialPlan && trialPeriod === 0) {
                errorMessage = 'please enter trial period'
            };
            alert.show(errorMessage, { type: alertTypes.ERROR });
            setPending(false);
            return;
        };
        if (planScreen === screen_type.subscription) {
            variables.subscriptionId = isEditData?.id;
            variables.subscription = isEditData?.subscription;
            variables.subscriptionFor = userType === roleTypes.MANUFACTURER ? roleTypes.MANUFACTURER : roleTypes.CUSTOMER;
            variables.isTrialPlan = DataHandler.isBooleanValue(isEditData?.isTrialPlan);
            variables.trialPeriod = DataHandler.isBooleanValue(isEditData?.isTrialPlan) ? DataHandler.isIntegerValue(isEditData?.trialPeriod) : 0;
            requestURL = Global.UPDATE_SUBSCRIPTION;
            showMessage = 'Subscription updated successfully';
        } else {
            variables.planId = isEditData?.id;
            variables.planName = isEditData?.planName;
            variables.planFor = userType === roleTypes.MANUFACTURER ? roleTypes.MANUFACTURER : roleTypes.CUSTOMER;
        };
        variables.description = `${DataHandler.isIntegerValue(isEditData?.noOfTokens)?.toLocaleString()} Tokens for $${DataHandler.isIntegerValue(isEditData?.amount)?.toLocaleString()} ${planScreen === screen_type.subscription ? 'per month' : ''}`;
        variables.amount = DataHandler.isIntegerValue(isEditData?.amount)?.toString();
        variables.noOfTokens = planScreen === screen_type.subscription ? DataHandler.isIntegerValue(isEditData?.noOfTokens) : isEditData?.noOfTokens;
        request = getRequestForApi(requestURL, variables, methodType.POST);
        await callHttpRequest(request).then(async (response) => {
            if (response?.status === 201 && response?.data?.error === 201) {
                alert.show(showMessage, { type: alertTypes.SUCCESS });
                getPlansList();
                setIsEditData(null);
                setNewPlan(null);
            } else {
                alert.show(response?.data?.response?.data, { type: alertTypes.ERROR });
            };
            setPending(false);
        }).catch((err) => {
            setPending(false);
        });
    };

    const deletePlan = async () => {
        setPending(true);
        let variables = {}, request, requestURL = Global.DELETE_PLAN, successMessage = 'Plan deleted successfully';
        if (planScreen !== screen_type.subscription) {
            variables.planId = DataHandler.isIntegerValue(deleteData?.id);
        } else {
            variables.subscriptionId = DataHandler.isIntegerValue(deleteData?.id);
            requestURL = Global.DELETE_SUBSCRIPTION;
            successMessage = 'Subscription deleted successfully';
        };
        request = getRequestForApi(requestURL, variables, methodType.POST)
        await callHttpRequest(request).then(async (response) => {
            if (response?.status === 201) {
                alert.show(successMessage, { type: alertTypes.SUCCESS });
                getPlansList();
                setIsEditData(null);
                handelDelete()
            };
            setPending(false);
        }).catch((err) => {
            setPending(false);
        });

    };

    const plan_columns = [
        {
            name: <th>Plan Name</th>,
            selector: row => row?.planName,
            sortable: true,
            cell: row => (<>
                {isEditData && DataHandler.isIntegerValue(isEditData?.id) === DataHandler.isIntegerValue(row?.id) ?
                    <CustomInput.Text setData={setIsEditData} keyName={"planName"} minValue="1" value={DataHandler.isStringValue(isEditData?.['planName'])} className="form-control default-font" id="recipient-name" placeholder="Enter Plan" /> :
                    row?.planName
                }
            </>)
        },
        {
            name: <th>Token</th>,
            selector: row => row?.noOfTokens,
            sortable: true,
            cell: row => (<>
                {isEditData && DataHandler.isIntegerValue(isEditData?.id) === DataHandler.isIntegerValue(row?.id) ?
                    <CustomInput.Number setData={setIsEditData} keyName={"noOfTokens"} minValue="1" maxValue={'99999'} value={DataHandler.isStringValue(isEditData?.['noOfTokens'])} className="form-control default-font" id="recipient-name" placeholder="Enter tokens" /> :
                    DataHandler.isIntegerValue(row?.noOfTokens)?.toLocaleString()
                }
            </>)
        },
        {
            name: <th>Price</th>,
            selector: row => row?.amount,
            sortable: true,
            cell: row => (<>
                {isEditData && DataHandler.isIntegerValue(isEditData?.id) === DataHandler.isIntegerValue(row?.id) ?
                    <CustomInput.Number setData={setIsEditData} keyName={"amount"} minValue="1" maxValue={'99999'} value={DataHandler.isStringValue(isEditData?.['amount'])} className="form-control default-font" id="recipient-name" placeholder="Enter amount" /> :
                    "$" + DataHandler.isIntegerValue(row?.amount)?.toLocaleString()
                }
            </>)
        },
        {
            name: <th>Action</th>,
            selector: row => row?.id,
            cell: row => (<div className='edit-delete-btn'>
                {isEditData && DataHandler.isIntegerValue(isEditData?.id) === DataHandler.isIntegerValue(row?.id) ?
                    <button className='btn btn-secondary' onClick={() => handleSubmit('update')}>Save</button> :
                    <button className='btn btn-light btn-border'
                        onClick={() => {
                            if (isEditData && DataHandler.isIntegerValue(isEditData?.id) !== DataHandler.isIntegerValue(row?.id)) {
                                alert.show('Please save selected plan first', { type: alertTypes.INFO });
                                return;
                            }
                            setIsEditData(row)
                        }}
                    >Edit</button>
                }
                {isEditData && DataHandler.isIntegerValue(isEditData?.id) === DataHandler.isIntegerValue(row?.id) ?
                    <button className='btn btn-danger' onClick={() => setIsEditData(null)}>Discard</button> :
                    <button className='btn btn-danger' onClick={() => { handelDelete(row); setPageSource(false) }}>Delete</button>}
            </div>)
        }
    ];

    const subscription_columns = [
        {
            name: <th>Subscription</th>,
            selector: row => row?.subscription,
            sortable: true,
            cell: row => (<>
                {isEditData && DataHandler.isIntegerValue(isEditData?.id) === DataHandler.isIntegerValue(row?.id) ?
                    <CustomInput.Text setData={setIsEditData} keyName={"subscription"} minValue="1" value={DataHandler.isStringValue(isEditData?.['subscription'])} className="form-control default-font" id="recipient-name" placeholder="Enter subscription" /> :
                    row?.subscription
                }
            </>)
        },
        {
            name: <th>Token</th>,
            selector: row => row?.noOfTokens,
            sortable: true,
            cell: row => (<>
                {isEditData && DataHandler.isIntegerValue(isEditData?.id) === DataHandler.isIntegerValue(row?.id) ?
                    <CustomInput.Number setData={setIsEditData} keyName={"noOfTokens"} minValue="1" maxValue={'99999'} value={DataHandler.isStringValue(isEditData?.['noOfTokens'])} className="form-control default-font" id="recipient-name" placeholder="Enter tokens" /> :
                    DataHandler.isIntegerValue(row?.noOfTokens)?.toLocaleString()
                }
            </>)
        },
        {
            name: <th>Price</th>,
            selector: row => row?.amount,
            sortable: true,
            cell: row => (<>
                {isEditData && DataHandler.isIntegerValue(isEditData?.id) === DataHandler.isIntegerValue(row?.id) ?
                    DataHandler.isBooleanValue(isEditData?.isTrialPlan) ? '$0' :
                        <CustomInput.Number setData={setIsEditData} keyName={"amount"} minValue="1" maxValue={'99999'} value={DataHandler.isStringValue(isEditData?.['amount'])} className="form-control default-font" id="recipient-name" placeholder="Enter amount" /> :
                    "$" + DataHandler.isIntegerValue(row?.amount)?.toLocaleString()
                }
            </>)
        },
        {
            name: <th>Trial Plan</th>,
            selector: row => row?.isTrialPlan,
            sortable: true,
            center: true,
            cell: row => (<>
                {isEditData && DataHandler.isIntegerValue(isEditData?.id) === DataHandler.isIntegerValue(row?.id) ?
                    <input type='checkbox' value={DataHandler.isBooleanValue(isEditData?.isTrialPlan)} checked={DataHandler.isBooleanValue(isEditData?.isTrialPlan)} onChange={() => setIsEditData({ ...isEditData, isTrialPlan: !isEditData?.isTrialPlan })} /> :
                    <li>
                        <span data-tooltip={row?.isTrialPlan}>
                            <span className='ellipsis'>
                                {DataHandler.isBooleanValue(row?.isTrialPlan) ? <span className="logged-in">●</span> : <span className="logged-out">●</span>}
                            </span>
                        </span>
                    </li>
                }
            </>)
        },
        {
            name: <th>Trial Period</th>,
            selector: row => row?.trialPeriod,
            sortable: true,
            cell: row => (<>
                {isEditData && DataHandler.isIntegerValue(isEditData?.id) === DataHandler.isIntegerValue(row?.id) ?
                    DataHandler.isBooleanValue(isEditData?.isTrialPlan) ?
                        <CustomInput.Number setData={setIsEditData} keyName={"trialPeriod"} minValue="1" maxValue={'999'} value={DataHandler.isStringValue(isEditData?.['trialPeriod'])} className="form-control default-font" id="recipient-name" placeholder="Enter number of days" /> : 0
                    : DataHandler.isIntegerValue(row?.trialPeriod)
                }
            </>)
        },
        {
            name: <th>Action</th>,
            selector: row => row?.id,
            cell: row => (<div className='edit-delete-btn'>
                {isEditData && DataHandler.isIntegerValue(isEditData?.id) === DataHandler.isIntegerValue(row?.id) ?
                    <button className='btn btn-secondary' onClick={() => handleSubmit('update')}>Save</button> :
                    <button className='btn btn-light btn-border'
                        onClick={() => {
                            if (isEditData?.id && DataHandler.isIntegerValue(isEditData?.id) !== DataHandler.isIntegerValue(row?.id)) {
                                alert.show('Please save selected subscription plan first', { type: alertTypes.INFO });
                                return;
                            }
                            setIsEditData(row)
                        }}
                    >Edit</button>
                }
                {isEditData && DataHandler.isIntegerValue(isEditData?.id) === DataHandler.isIntegerValue(row?.id) ?
                    <button className='btn btn-danger' onClick={() => setIsEditData(null)}>Discard</button> :
                    <button className='btn btn-danger' onClick={() => { handelDelete(row); setPageSource(true) }}>Delete</button>
                }
            </div>)
        }
    ];

    const handleSearchData = (value) => {
        const searchData = plansData.filter(obj =>
            obj?.planName?.toLocaleLowerCase().includes(value) || obj?.subscription?.toLocaleLowerCase().includes(value) || obj?.noOfTokens?.toString().includes(value) || obj?.amount?.toString().includes(value));
        if (searchData && value) {
            setPlansList(searchData)
        } else {
            setPlansList(plansData);
        };
    };

    const disableSubmit = () => {
        if (planScreen !== screen_type.free_token && (!newPlan?.planName || !newPlan?.amount || !newPlan?.noOfTokens)) return true;
        if (planScreen === screen_type.free_token && !freeTokens?.freeTokens) return true;
    };

    const handelDelete = (props) => {
        setDeleteData(props)
        setDeletePopUp(!deletePopUp)
    };

    return (
        <>

            {<CustomLoader pending={pending} />}
            <div className={`modal ${isShow ? "show" : "fade"}`} id="modal" tabIndex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className={`modal-content ${planScreen === screen_type.subscription ? "modal-content-width" : ""}`}>
                        <div className="modal-header hide-border">
                            <div className="segmented-controls">
                                <div className="btn-group" role="group" aria-label="Basic example">
                                    <button type="button" className={`btn ${planScreen === screen_type.free_token ? 'btn-primary bg-colour-lght' : 'btn-primary'} btn-sm`} onClick={() => setPlanScreen(screen_type.free_token)}>{'Free Token'}</button>
                                    <button type="button" className={`btn ${planScreen === screen_type.plan ? 'btn-primary bg-colour-lght' : 'btn-primary'} btn-sm`} onClick={() => setPlanScreen(screen_type.plan)}>{'Plan'}</button>
                                    {source === sourceName.businessLite ? <button type="button" className={`btn ${planScreen === screen_type.subscription ? 'btn-primary bg-colour-lght' : 'btn-primary'} btn-sm`} onClick={() => setPlanScreen(screen_type.subscription)}>{'Subscription'}</button> : <></>}
                                </div>
                            </div>
                            <div className="close">
                                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={() => handleClose()} />
                            </div>
                        </div>
                        <div className="modal-body">
                            {planScreen === screen_type.free_token ? <form className='p-0'>
                                <div className="form-group">
                                    <label htmlFor="recipient-name" className="col-form-label">Token:</label>
                                    <CustomInput.Number setData={setFreeTokens} keyName={"freeTokens"} minValue="1" maxValue={'99999'} value={DataHandler.isStringValue(freeTokens?.['freeTokens'])} className="form-control default-font" id="recipient-name" placeholder="free token" />
                                </div>
                            </form> : <form className='p-0'>
                                <div className="form-group">
                                    <label htmlFor="recipient-name" className="col-form-label">{planScreen === screen_type.subscription ? 'Subscription' : 'Plan'} Name</label>
                                    <CustomInput.Text
                                        setData={setNewPlan}
                                        keyName={"planName"}
                                        value={DataHandler.isStringValue(newPlan?.['planName'])}
                                        className="form-control default-font"
                                        minValue="1"
                                        placeholder={planScreen === screen_type.subscription ? "Enter subscription name" : "Enter plan name"}
                                        id="recipient-name"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message-text" className="col-form-label">Token</label>
                                    <CustomInput.Number setData={setNewPlan} keyName={"noOfTokens"} minValue="1" maxValue={'99999'} value={DataHandler.isStringValue(newPlan?.['noOfTokens'])} className="form-control default-font" placeholder="Enter tokens" id="message-text" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message-text" className="col-form-label">Price</label>
                                    <CustomInput.Number setData={setNewPlan} keyName={"amount"} minValue="1" maxValue={'99999'} value={DataHandler.isStringValue(newPlan?.['amount'])} className="form-control default-font" placeholder="Enter amount" id="message-text" readOnly={DataHandler.isBooleanValue(newPlan?.isTrialPlan)} />
                                </div>

                                {planScreen === screen_type.subscription ? <>
                                    <div className="form-group">
                                        <label htmlFor="message-text" className="col-form-label">Free Trial</label>&nbsp;&nbsp;
                                        <input type='checkbox' value={DataHandler.isBooleanValue(newPlan?.isTrialPlan)} checked={DataHandler.isBooleanValue(newPlan?.isTrialPlan)} onChange={() => setNewPlan({ ...newPlan, isTrialPlan: !newPlan?.isTrialPlan })} />
                                    </div>
                                    {DataHandler.isBooleanValue(newPlan?.isTrialPlan) ? <div className="form-group">
                                        <label htmlFor="message-text" className="col-form-label">Trial Period</label>
                                        <CustomInput.Number setData={setNewPlan} keyName={"trialPeriod"} minValue="1" maxValue={'999'} value={DataHandler.isStringValue(newPlan?.['trialPeriod'])} className="form-control default-font" placeholder="Enter number of days" id="message-text" />
                                    </div> : <></>}
                                </> : <></>}
                            </form>}
                        </div>
                        <div className="modal-footer hide-border me-3 d-flex justify-content-between align-items-normal ps-4 ms-3">
                            <div class="input-group mb-3 position-relative w-50">
                                {planScreen !== screen_type.free_token && <>
                                    <input
                                        type="search"
                                        className="form-control search-bar-plans"
                                        placeholder="Search ......"
                                        aria-label="Recipient's username"
                                        onChange={(event) => handleSearchData(event.target.value.toLocaleLowerCase())}
                                        autoComplete="off"
                                    />
                                </>}
                            </div>

                            <div className='d-flex gap-3'>
                                <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal" onClick={() => handleClose()}>Cancel</button>
                                <button type="button" className={`btn btn-primary btn-sm ${disableSubmit() ? 'disable-button' : " "}`} onClick={() => {
                                    handleSubmit('add')
                                }}>Save</button>
                            </div>
                        </div>
                        {planScreen !== screen_type.free_token && <div className='plans-modal-table'>
                            <CommonTable
                                columns={planScreen === screen_type.subscription ? subscription_columns : plan_columns}
                                data={plansList}
                            />
                        </div>}
                    </div>
                </div>
            </div>
            <DeleteConfirm onSubmit={deletePlan} onClose={handelDelete} showPopUp={deletePopUp} propsData={deleteData} planScreen={pageSource} />
        </>
    )
}

export default PlansModal