import React from 'react'
import DataTable from 'react-data-table-component';
import * as DataHandler from '../utility-files/data-util/DataHandler';

function CommonTable(props) {
    const { columns, data, ...rest } = props;
    const tableCustomStyles = {
        headCells: {
            style: {
                    backgroundColor: '#F1F1F9',
                    // backgroundColor: 'rgb(226 226 226)'
            },
        },
    };
    return (
        <>
            <DataTable
                columns={columns}
                data={DataHandler.isArrayValue(data)}
                striped
                highlightOnHover
                pagination
                paginationPerPage={10}
                // width={"5%"}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                paginationComponentOptions={{
                    rowsPerPageText: 'Records per page:',
                    rangeSeparatorText: 'out of '
                }}
                customStyles={tableCustomStyles}
                {...rest}
            />

        </>
    )
}

export default CommonTable  