import React, { useState } from 'react'
import CustomLoader from '../custom-modules/CustomLoader';
import * as Global from '../../constants/Global'
import { alertTypes, downloadXMLfile, exportUserFileName, sourceName } from '../../utility-files/helper-function/HelperFunction';
import { useAlert } from 'react-alert';
import ApiHelper from '../../config/ApiHelper';

function Confirmation({ isShow, onSuccess, onClose, userIds, source }) {
    const [pending, setPending] = useState(false);
    const alert = useAlert();
    const callExportTokensApi = async () => {
        let requestUrl, fileName;
        setPending(true);
        requestUrl = (source === sourceName.businessLite) ? Global.EXPORT_BUSINESSLITE_TOKENS : Global.EXPORT_CONSUMERLITE_TOKENS;
        for (let index = 0; index < userIds?.userIds?.length; index++) {
            let sign = index === 0 ? "?" : "&";
            requestUrl += sign + `ids=${userIds?.userIds?.[index]}`
        };
        fileName = exportUserFileName(source, '_tokens_list');
        await ApiHelper.get(requestUrl, { responseType: 'blob' }).then((response) => {
            if (response?.status === 200) {
                downloadXMLfile(response?.data, fileName, onClose());
                onSuccess();
                alert.show('File downloaded successfully', { type: alertTypes.SUCCESS });
            }
            setPending(false);
        }).catch((err) => {
            const error = err?.response?.data;
            const blb = new Blob([error], { type: "text/plain" });
            const reader = new FileReader();

            // This fires after the blob has been read/loaded.
            reader.addEventListener('loadend', (e) => {
                const text = e.srcElement.result;
                alert.show(JSON.parse(text)?.message, { type: alertTypes.ERROR });
                onClose();
            });
            // Start reading the blob as text.
            reader.readAsText(blb)
            setPending(false);
        });
    };

    return (
        <>
            {<CustomLoader pending={pending} />}
            <div className={`modal ${isShow ? "show" : "fade"} bd-example-modal-sm`} id="modal" tabIndex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header hide-border d-flex justify-content-between align-items-center">
                            <div>
                                <p className='mb-0'>Are you sure you want to export user's tokens list?</p>
                            </div>

                            {/* <div className="close" onClick={() => onClose()}>
                                <button type="button" className="btn-close" aria-label="Close" />
                            </div> */}
                        </div>
                        {/* <div className="modal-body">

                        </div> */}
                        <div className="modal-footer d-flex justify-content-end mt-2">
                            <button type="button" className="btn btn-secondary" onClick={() => callExportTokensApi()}>Yes</button>
                            <button type="button" className="btn btn-primary" onClick={() => onClose('close')}>No</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Confirmation